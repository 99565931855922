@use "homepage/activity";



.home-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    background-color: #000;
    height: 100vh;
    width: 100vw;
}

.home-grid {
    display: grid;
    margin: 2%;
    padding: 2rem;
    flex-direction: column;
    flex: 1;

    border: solid 0.5em white;
}

.menu-container {
    margin: auto 0 2.5% 2.5%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: space-around;
    align-items: baseline;
}

.menu {
    font-family: 'Courier New', Courier, monospace;
    font-weight: lighter;
    font-size: large;

    list-style: none;
}

.menu>li {
    margin: 0.75rem;
}

.menu>li>a {
    padding: 0.25rem 3rem 0.25rem 0.5rem;
    text-decoration: none;

    color: #fff;
    background-color: #101114;
}

.menu>li>a:hover {
    padding: 0.75rem 3rem 0.75rem 0.5rem;
    margin: 0.75rem 0rem;

    background-color: blue;
}

.menu>li>a:hover::before {
    text-decoration: none;
    content: "// ";
}