.center {
    top: 50%;
    left: 50%;
    position: absolute;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.activities {
    padding-top: 0.5rem;
    margin: 0rem 0rem 0 0.5rem;
    color: gray;
}

.activity {
    padding: 0 0 1rem 1rem;
    border-left: 0.25rem solid #ffffff;
}

.discord-box {
    display: grid;
    grid-template-areas: "discord-avatar" "username" "view-profile";
    grid-template-columns: 1fr 3fr 2fr;
    color: white;
}

.discord-avatar {
    width: 4rem;
    border-radius: 4rem;
}

.username {
    grid-area: auto;
    /* height: 100%; */
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    /* padding-left: 1rem; */
    color: white;
}

.button-container {
    grid-area: auto;
    display: flex;

    text-align: center;
    justify-items: center;
    align-items: center;
    justify-content: flex-end;

    color: white;
}

.spotify-logo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0;
}

.spotify-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.spotify-title>div>small {
    color: lightgray;
}

.view-profile {
    text-align: center;

    height: fit-content;
    width: fit-content;

    padding: 0.5rem;

    border-radius: 0.25rem;
    border: none;

    background-color: #5865f2 !important;
    border-color: #ffffff !important;

    color: white;

    transition-duration: 0.2s
}

.view-profile:hover {
    background-color: #4852c4 !important;
    color: white
}