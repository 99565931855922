@import "https://fonts.googleapis.com/css?family=Oswald";
.App {
  height: 100%;
  background-color: #000;
  color: #fff;
  font-family: "Oswald" sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.center {
  top: 50%;
  left: 50%;
  position: absolute;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.activities {
  padding-top: 0.5rem;
  margin: 0rem 0rem 0 0.5rem;
  color: gray;
}

.activity {
  padding: 0 0 1rem 1rem;
  border-left: 0.25rem solid #ffffff;
}

.discord-box {
  display: grid;
  grid-template-areas: "discord-avatar" "username" "view-profile";
  grid-template-columns: 1fr 3fr 2fr;
  color: white;
}

.discord-avatar {
  width: 4rem;
  border-radius: 4rem;
}

.username {
  grid-area: auto;
  /* height: 100%; */
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  /* padding-left: 1rem; */
  color: white;
}

.button-container {
  grid-area: auto;
  display: flex;
  text-align: center;
  justify-items: center;
  align-items: center;
  justify-content: flex-end;
  color: white;
}

.spotify-logo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0;
}

.spotify-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.spotify-title > div > small {
  color: lightgray;
}

.view-profile {
  text-align: center;
  height: fit-content;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: none;
  background-color: #5865f2 !important;
  border-color: #ffffff !important;
  color: white;
  transition-duration: 0.2s;
}

.view-profile:hover {
  background-color: #4852c4 !important;
  color: white;
}

.home-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #000;
  height: 100vh;
  width: 100vw;
}

.home-grid {
  display: grid;
  margin: 2%;
  padding: 2rem;
  flex-direction: column;
  flex: 1;
  border: solid 0.5em white;
}

.menu-container {
  margin: auto 0 2.5% 2.5%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
  align-items: baseline;
}

.menu {
  font-family: "Courier New", Courier, monospace;
  font-weight: lighter;
  font-size: large;
  list-style: none;
}

.menu > li {
  margin: 0.75rem;
}

.menu > li > a {
  padding: 0.25rem 3rem 0.25rem 0.5rem;
  text-decoration: none;
  color: #fff;
  background-color: #101114;
}

.menu > li > a:hover {
  padding: 0.75rem 3rem 0.75rem 0.5rem;
  margin: 0.75rem 0rem;
  background-color: blue;
}

.menu > li > a:hover::before {
  text-decoration: none;
  content: "// ";
}

footer {
  text-align: center;
  width: 100%;
}

.name-container {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-weight: bolder;
  font-size: 10vw;
  margin-top: auto;
  bottom: 0;
}

.name-container::after {
  content: "";
}

.trail-container {
  display: flex;
  flex-wrap: wrap;
  width: 500px;
  height: 400px;
}

.trail-elements {
  height: 60px;
  width: 60px;
  background: grey;
}

.name-container {
  position: absolute;
  top: 5%;
  left: 5%;
  font-family: "Oswald", sans-serif;
  font-style: italic;
}

.name-glitch, .name-glow {
  color: rgb(223, 191, 191);
  position: relative;
  font-size: 5rem;
  font-weight: bold;
  animation: glitch 5s 5s infinite;
}

.name-glitch::before, .name-glow::before {
  content: "SIENNA TRUONG";
  position: absolute;
  left: -0.05rem;
  text-shadow: -0.2rem 0 magenta;
  text-wrap: nowrap;
  overflow: hidden;
  top: 0;
  animation: noise-1 3s linear infinite alternate-reverse, glitch 5s 5.05s infinite;
}

.name-glitch::after, .name-glow::after {
  content: "SIENNA TRUONG";
  position: absolute;
  left: 0.05rem;
  text-shadow: -0.2rem 0 lightgreen;
  text-wrap: nowrap;
  overflow: hidden;
  top: 0;
  animation: noise-2 3s linear infinite alternate-reverse, glitch 5s 5s infinite;
}

@keyframes glitch {
  1% {
    transform: rotateX(10deg) skewX(90deg);
  }
  2% {
    transform: rotateX(0deg) skewX(0deg);
  }
}
@keyframes noise-1 {
  3.3333333333% {
    clip-path: inset(15px 0 78px 0);
  }
  6.6666666667% {
    clip-path: inset(33px 0 25px 0);
  }
  10% {
    clip-path: inset(100px 0 1px 0);
  }
  13.3333333333% {
    clip-path: inset(75px 0 26px 0);
  }
  16.6666666667% {
    clip-path: inset(17px 0 42px 0);
  }
  20% {
    clip-path: inset(33px 0 15px 0);
  }
  23.3333333333% {
    clip-path: inset(82px 0 16px 0);
  }
  26.6666666667% {
    clip-path: inset(73px 0 7px 0);
  }
  30% {
    clip-path: inset(26px 0 8px 0);
  }
  33.3333333333% {
    clip-path: inset(47px 0 25px 0);
  }
  36.6666666667% {
    clip-path: inset(20px 0 81px 0);
  }
  40% {
    clip-path: inset(64px 0 18px 0);
  }
  43.3333333333% {
    clip-path: inset(11px 0 11px 0);
  }
  46.6666666667% {
    clip-path: inset(34px 0 18px 0);
  }
  50% {
    clip-path: inset(98px 0 1px 0);
  }
  53.3333333333% {
    clip-path: inset(30px 0 6px 0);
  }
  56.6666666667% {
    clip-path: inset(99px 0 1px 0);
  }
  60% {
    clip-path: inset(19px 0 37px 0);
  }
  63.3333333333% {
    clip-path: inset(62px 0 10px 0);
  }
  66.6666666667% {
    clip-path: inset(83px 0 6px 0);
  }
  70% {
    clip-path: inset(2px 0 31px 0);
  }
  73.3333333333% {
    clip-path: inset(4px 0 87px 0);
  }
  76.6666666667% {
    clip-path: inset(98px 0 2px 0);
  }
  80% {
    clip-path: inset(25px 0 42px 0);
  }
  83.3333333333% {
    clip-path: inset(100px 0 1px 0);
  }
  86.6666666667% {
    clip-path: inset(5px 0 47px 0);
  }
  90% {
    clip-path: inset(80px 0 13px 0);
  }
  93.3333333333% {
    clip-path: inset(67px 0 31px 0);
  }
  96.6666666667% {
    clip-path: inset(49px 0 18px 0);
  }
  100% {
    clip-path: inset(61px 0 6px 0);
  }
}
@keyframes noise-2 {
  0% {
    clip-path: inset(19px 0 43px 0);
  }
  3.3333333333% {
    clip-path: inset(88px 0 7px 0);
  }
  6.6666666667% {
    clip-path: inset(51px 0 18px 0);
  }
  10% {
    clip-path: inset(18px 0 23px 0);
  }
  13.3333333333% {
    clip-path: inset(46px 0 13px 0);
  }
  16.6666666667% {
    clip-path: inset(99px 0 2px 0);
  }
  20% {
    clip-path: inset(58px 0 38px 0);
  }
  23.3333333333% {
    clip-path: inset(28px 0 60px 0);
  }
  26.6666666667% {
    clip-path: inset(67px 0 16px 0);
  }
  30% {
    clip-path: inset(33px 0 54px 0);
  }
  33.3333333333% {
    clip-path: inset(57px 0 16px 0);
  }
  36.6666666667% {
    clip-path: inset(7px 0 87px 0);
  }
  40% {
    clip-path: inset(23px 0 32px 0);
  }
  43.3333333333% {
    clip-path: inset(5px 0 78px 0);
  }
  46.6666666667% {
    clip-path: inset(80px 0 4px 0);
  }
  50% {
    clip-path: inset(90px 0 4px 0);
  }
  53.3333333333% {
    clip-path: inset(4px 0 48px 0);
  }
  56.6666666667% {
    clip-path: inset(51px 0 36px 0);
  }
  60% {
    clip-path: inset(59px 0 2px 0);
  }
  63.3333333333% {
    clip-path: inset(42px 0 47px 0);
  }
  66.6666666667% {
    clip-path: inset(25px 0 60px 0);
  }
  70% {
    clip-path: inset(68px 0 19px 0);
  }
  73.3333333333% {
    clip-path: inset(44px 0 21px 0);
  }
  76.6666666667% {
    clip-path: inset(73px 0 21px 0);
  }
  80% {
    clip-path: inset(34px 0 61px 0);
  }
  83.3333333333% {
    clip-path: inset(77px 0 15px 0);
  }
  86.6666666667% {
    clip-path: inset(18px 0 45px 0);
  }
  90% {
    clip-path: inset(78px 0 7px 0);
  }
  93.3333333333% {
    clip-path: inset(38px 0 46px 0);
  }
  96.6666666667% {
    clip-path: inset(13px 0 31px 0);
  }
  100% {
    clip-path: inset(16px 0 71px 0);
  }
}
.scanlines {
  overflow: hidden;
  mix-blend-mode: difference;
}

.scanlines::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: repeating-linear-gradient(to bottom, transparent 0%, rgba(255, 255, 255, 0.05) 0.5%, transparent 1%);
  animation: fudge 7s ease-in-out alternate infinite;
}

@keyframes fudge {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, 2%);
  }
}
.name-glow {
  text-shadow: 0 0 10rem rgb(223, 191, 191);
  color: transparent;
  position: absolute;
  top: 0;
}

.subtitle {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
  font-size: 0.8vw;
  color: rgba(165, 141, 141, 0.04);
  text-transform: uppercase;
  letter-spacing: 1em;
  text-align: center;
  position: absolute;
  left: 17%;
  animation: glitch-2 5s 5.02s infinite;
}

@keyframes glitch-2 {
  1% {
    transform: rotateX(10deg) skewX(70deg);
  }
  2% {
    transform: rotateX(0deg) skewX(0deg);
  }
}
/* Creates hexagon */
.hexagon {
  width: 12rem;
  height: 6.85rem;
  position: absolute;
  z-index: 88;
}

.hexagon::before,
.hexagon::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 88;
}

.hexagon::before {
  transform: rotate(60deg);
}

.hexagon::after {
  transform: rotate(-60deg);
}

.hexagon,
.hexagon::before,
.hexagon::after {
  background: transparent;
  border: none;
  /* border-left: solid 1px #fff; */
  /* border-right: solid 1px #fff; */
}

/* Animating hexagon */
.hexagon,
.hexagon::before,
.hexagon::after {
  animation-name: hexagon-flash;
  animation-delay: 2s;
  animation-duration: 0.15s;
  animation-timing-function: linear;
  animation-iteration-count: 2;
}

@keyframes hexagon-flash {
  0% {
    border-left: solid 1px #fff;
    border-right: solid 1px #fff;
  }
  50% {
    border: none;
  }
  100% {
    border-left: solid 1px #fff;
    border-right: solid 1px #fff;
  }
}
.star {
  width: 12rem;
  height: 12rem;
  background: transparent;
  position: absolute;
  transform: translateX(6rem);
}

.star::before,
.star::after {
  content: "";
  width: 100%;
  height: 100%;
  /* background: #fff; */
  position: absolute;
  z-index: 88;
  /* border-left: solid 1px #fff; */
}

.star::before {
  transform: rotate(60deg) translateX(2.9rem) translateY(5.2rem);
}

.star::after {
  transform: rotate(-60deg) translateX(2.9rem) translateY(-5.2rem);
}

.star,
.star::before,
.star::after {
  animation-name: star-flash;
  animation-delay: 2.1s;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

@keyframes star-flash {
  0% {
    border-left: solid 1px #fff;
  }
  18% {
    border: none;
  }
  36% {
    border: none;
  }
  54% {
    border: none;
  }
  /* 72% { border-left: solid 1px #fff; } */
  90% {
    border: none;
  }
  100% {
    border-left: solid 1px #fff;
  }
}
.spin-hex {
  width: 12rem;
  height: 6.85rem;
  background-color: #fff;
  position: absolute;
  z-index: 88;
}

.spin-hex::before,
.spin-hex::after {
  content: "";
  width: 100%;
  height: 100%;
  /* background: #fff; */
  position: absolute;
  z-index: 88;
}

.spin-hex::before {
  transform: rotate(60deg);
}

.spin-hex::after {
  transform: rotate(-60deg);
}

.spin-hex,
.spin-hex::before,
.spin-hex::after {
  background: transparent;
}

.spin-hex,
.spin-hex::before,
.spin-hex::after {
  animation-delay: 2.4s;
  animation-duration: 2.8s;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  animation-iteration-count: 1;
}

.spin-hex::before,
.spin-hex::after {
  animation-name: hex-spin-grow !important;
}

@keyframes hex-spin-grow {
  0% {
    scale: 0.4;
    border-left: solid 1px #fff;
    border-right: solid 1px #fff;
  }
  70% {
    scale: 0.95;
    rotate: z 180deg;
    border-left: solid 1px #fff;
    border-right: solid 1px #fff;
  }
  100% {
    border-left: solid 1px #fff;
    border-right: solid 1px #fff;
  }
}
.terminal {
  background-color: #000 !important;
  color: #fff !important;
  font-family: "Courier New", Courier, monospace;
  border-radius: 0 !important;
  border-style: groove !important;
  border: solid 0.2rem #fff !important;
  height: 60vh !important;
  width: 60vh !important;
  visibility: visible;
  opacity: 0%;
  animation-name: flash;
  animation-delay: 6s;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

.terminal-card-header {
  background-color: #fff !important;
  color: #000 !important;
  font-weight: bold !important;
  font-size: larger !important;
  padding: 0.2rem 0.6rem !important;
  border-radius: 0 !important;
}

.terminal-line {
  list-style: "> ";
  margin: 0;
}

.terminal-header {
  color: gray;
  display: inline;
}

.terminal-content {
  display: inline;
}

@keyframes flash {
  0% {
    opacity: 0%;
  }
  1% {
    opacity: 100%;
  }
  2% {
    opacity: 0%;
  }
  3% {
    opacity: 100%;
  }
  4% {
    opacity: 0%;
  }
  5% {
    opacity: 100%;
  }
  70% {
    opacity: 100%;
  }
  80% {
    opacity: 0%;
  }
  100% {
    opacity: 0%;
  }
}
.terminal-dot {
  width: 2rem;
  height: 2rem;
  left: 30%;
  bottom: 30%;
  position: absolute;
  background-image: none;
  background-position: 0.5rem 0.5rem;
  background-size: 0.5rem 0.5rem;
  background-color: rgb(0, 0, 0);
  animation-name: dot-flash;
  animation-duration: 0.6s;
  animation-timing-function: linear;
  animation-iteration-count: 3;
}

.sequence-1 {
  animation-delay: 3s;
}

.sequence-2 {
  animation-delay: 4.2s;
}

@keyframes dot-flash {
  0% {
    background-image: none;
  }
  50% {
    background-image: radial-gradient(rgb(255, 255, 255) 26%, transparent 26%);
  }
  100% {
    background-image: none;
  }
}
.triangle {
  width: 12rem;
  height: 12rem;
  background: #fff;
  position: absolute;
  z-index: 88;
}

.triangle::before,
.triangle::after {
  content: "";
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  z-index: 88;
}

.triangle {
  transform: translateY(21%);
}

.triangle::before {
  transform: rotate(30deg) translateX(-31.5%) translateY(-19%);
}

.triangle::after {
  transform: rotate(-30deg) translateX(31.5%) translateY(-19%);
}

.triangle,
.triangle::before,
.triangle::after {
  background: transparent;
}

/* .triangle { border-top: solid 1px #fff; }
.triangle::before { border-right: solid 1px #fff; }
.triangle::after { border-left: solid 1px #fff; } */
.circle {
  width: 7rem;
  height: 7rem;
  background: transparent;
  position: absolute;
  z-index: 88;
  border-radius: 4rem;
  /* border: solid 1px #fff; */
  /* transform: translateY(-36%); */
}

.triangle,
.triangle::before,
.triangle::after,
.circle {
  animation-delay: 3s;
  animation-duration: 0.6s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

.triangle {
  animation-name: triangle-top;
}

.triangle::before {
  animation-name: triangle-left;
}

.triangle::after {
  animation-name: triangle-right;
}

.circle {
  animation-name: circle-flash;
}

@keyframes triangle-top {
  0% {
    border-top: solid 1px #fff;
  }
  18% {
    border: none;
  }
  36% {
    border: none;
  }
  54% {
    border: none;
  }
  72% {
    border-top: solid 1px #fff;
  }
  90% {
    border: none;
  }
  100% {
    border-top: solid 1px #fff;
  }
}
@keyframes triangle-left {
  0% {
    border-right: solid 1px #fff;
  }
  18% {
    border: none;
  }
  36% {
    border: none;
  }
  54% {
    border: none;
  }
  72% {
    border-right: solid 1px #fff;
  }
  90% {
    border: none;
  }
  100% {
    border-right: solid 1px #fff;
  }
}
@keyframes triangle-right {
  0% {
    border-left: solid 1px #fff;
  }
  18% {
    border: none;
  }
  36% {
    border: none;
  }
  54% {
    border: none;
  }
  72% {
    border-left: solid 1px #fff;
  }
  90% {
    border: none;
  }
  100% {
    border-left: solid 1px #fff;
  }
}
@keyframes circle-flash {
  0% {
    border: none;
  }
  18% {
    border: none;
  }
  36% {
    border: solid 1px #fff;
  }
  54% {
    border: none;
  }
  72% {
    border: none;
  }
  90% {
    border: none;
  }
  100% {
    border: solid 1px #fff;
  }
}
.main-container {
  background-color: black;
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: lighter;
  height: 100vh;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.establishing-container {
  text-align: left;
  animation-name: establishing-wait;
  animation-duration: 2s;
  animation-timing-function: linear;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
}

@keyframes establishing-wait {
  0% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
}
.epilepsy-warning {
  color: transparent;
  animation: fade-in-and-out 3s linear 0s 1;
}

@keyframes fade-in-and-out {
  0% {
    color: #fff;
  }
  80% {
    color: #fff;
  }
  90% {
    color: transparent;
  }
}
#scramble-1 {
  position: absolute;
  left: 32%;
  bottom: 30%;
  margin: 0;
  color: transparent;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
  font-weight: lighter;
  animation-delay: 5.7s;
  animation-name: scramble;
  animation-duration: 15s;
  animation-timing-function: linear;
}

@keyframes scramble {
  from {
    color: #fff;
  }
  to {
    color: #fff;
  }
}
#established-2 {
  position: absolute;
  color: transparent;
  font-weight: lighter;
  animation-name: established-flash;
  animation-delay: 3s;
  animation-duration: 1.4s;
  animation-timing-function: linear;
}

@keyframes established-flash {
  0% {
    color: #fff;
  }
  5% {
    color: transparent;
  }
  10% {
    color: #fff;
  }
  90% {
    color: #fff;
  }
}
#retrieving {
  position: absolute;
  color: transparent;
  font-weight: lighter;
  animation-name: retrieving;
  animation-delay: 13s;
  animation-duration: 5s;
  animation-timing-function: linear;
}

@keyframes retrieving {
  0% {
    color: #fff;
  }
  1% {
    color: transparent;
  }
  2% {
    color: #fff;
  }
  90% {
    color: #fff;
  }
}
.square-centered-container {
  text-align: left;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

