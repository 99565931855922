.terminal-dot {
    width: 2rem;
    height: 2rem;

    left: 30%;
    bottom: 30%;
    position: absolute;

    background-image: none;
    background-position: 0.5rem 0.5rem;
    background-size: 0.5rem 0.5rem;
    background-color: rgb(0, 0, 0);

    animation-name: dot-flash;
    animation-duration: 0.6s;
    animation-timing-function: linear;
    animation-iteration-count: 3;
}

.sequence-1 {
    animation-delay: calc(0s + 3s);
}
.sequence-2 {
    animation-delay: calc(4.2s + 0s);
}

@keyframes dot-flash {
    0% {
        background-image: none;
    }

    50% {
        background-image:
        radial-gradient(rgb(255, 255, 255) 26%, transparent 26%);
    }

    100% {
        background-image: none;
    }
}