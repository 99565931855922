.name-container {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-weight: bolder;
    font-size: 10vw;
    // width: 80%;

    margin-top: auto;
    bottom: 0;
}

.name-container::after {
    content: "";
}


.trail-container {
    display: flex;
    flex-wrap: wrap;
    width: 500px;
    height: 400px;
}

.trail-elements {
    height: 60px;
    width: 60px;
    background: grey;
}