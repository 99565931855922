@use "Hexagon";
@use "StarIntoHex";
@use "Terminal";
@use "TerminalDot";
@use "Triangle";

.main-container {
    background-color: black;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: lighter;
    height: 100vh;

    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
}

.establishing-container {
    text-align: left;

    animation-name: establishing-wait;
    animation-duration: 2s;
    animation-timing-function: linear;

    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
}

@keyframes establishing-wait {
    0% {
        color: transparent;
    }

    99% {
        color: transparent;
    }
}

.epilepsy-warning {
    color: transparent;
    animation: fade-in-and-out 3s linear 0s 1;
}
@keyframes fade-in-and-out {
    0% { color: #fff }
    80% { color: #fff }
    90% { color: transparent }
}

#scramble-1 {
    position: absolute;
    left: 32%;
    bottom: 30%;
    margin: 0;
    color: transparent;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;

    font-weight: lighter;

    animation-delay: calc(2.7s + 3s);
    animation-name: scramble;
    animation-duration: 15s;
    animation-timing-function: linear;
}
@keyframes scramble {
    from { color: #fff; }
    to { color: #fff; }
}

#established-2 {
    position: absolute;
    color: transparent;
    font-weight: lighter;

    animation-name: established-flash;
    animation-delay: calc(3s + 0s);
    animation-duration: 1.4s;
    animation-timing-function: linear;
}
@keyframes established-flash {
    0% {
        color: #fff;
    }
    5% {
        color: transparent;
    }
    10% {
        color: #fff;
    }
    90% {
        color: #fff;
    }
}

#retrieving {
    position: absolute;
    color: transparent;
    font-weight: lighter;

    animation-name: retrieving;
    animation-delay: calc(10s + 3s);
    animation-duration: 5s;
    animation-timing-function: linear;
}
@keyframes retrieving {
    0% { color: #fff; }
    1% { color: transparent; }
    2% { color: #fff; }
    90% { color: #fff; }
}

.square-centered-container {
    text-align: left;
}