/* Creates hexagon */
.hexagon {
    width: 12rem;
    height: 6.85rem;
    position: absolute;
    z-index: 88;
}

.hexagon::before,
.hexagon::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 88;
}

.hexagon::before {
    transform: rotate(60deg);
}

.hexagon::after {
    transform: rotate(-60deg);
}

.hexagon,
.hexagon::before,
.hexagon::after {
    background: transparent;
    border: none;
    /* border-left: solid 1px #fff; */
    /* border-right: solid 1px #fff; */
}

/* Animating hexagon */
.hexagon,
.hexagon::before,
.hexagon::after {
    animation-name: hexagon-flash;
    animation-delay: calc(2s + 0s);
    animation-duration: 0.15s;
    animation-timing-function: linear;
    animation-iteration-count: 2;
}

@keyframes hexagon-flash {
    0% {
        border-left: solid 1px #fff;
        border-right: solid 1px #fff;
    }

    50% {
        border: none;
    }

    100% {
        border-left: solid 1px #fff;
        border-right: solid 1px #fff;
    }
}